import { Outlet, Link } from "react-router-dom";
import UserContext from './UserContex';
import { useContext } from "react";

export default function Layout() {

  const { user } = useContext(UserContext);

  return (
    <>
      <div className="center-block">
        <nav>
          <div className="pure-menu pure-menu-horizontal">
            <ul className="pure-menu-list">
              {user && <li className="pure-menu-item">  <Link className="pure-menu-link" to="/leagues">Leagues</Link> </li>}
              {user && <li className="pure-menu-item">  <Link className="pure-menu-link" to="/logout">Logout<span> ({user.email})</span></Link> </li>}
              {!user && <li className="pure-menu-item"> <Link className="pure-menu-link" to="/login">Login</Link>         </li>}
              {!user && <li className="pure-menu-item"> <Link className="pure-menu-link" to="/registration">Registration</Link > </li>}
            </ul>
          </div>
        </nav>

        <Outlet />
      </div>
    </>
  );
}