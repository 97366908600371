import { useState, useEffect } from 'react';
import axios, { all } from 'axios'
import ServerParameters from "./ServerParameters";
import { useParams, useNavigate } from "react-router-dom";
import LeagueUserSearch from "./LeagueUserSearch";
import AddUser from './AddUser';
import { toast } from 'react-toastify';

export default function AddGamePage() {

    const [league, setLeague] = useState({});
    const [otherPlayersIndexArray, setOtherPlayersIndexArray] = useState([]);
    const [winner, setWinner] = useState({});
    const [otherPlayers, setOtherPlayers] = useState([]);
    const [gameDuration, setGameDuration] = useState();
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        loadLeague();
    }, []);

    function loadLeague() {
        axios
            .get(ServerParameters.API_URL + "/league/" + params.leagueId)
            .then(response => {
                setLeague(response.data);
                setOtherPlayersIndexArray([...Array(response.data.numberOfPlayersInOneGame - 1).keys()]);
                const players = [];
                for (let i = 0; i < response.data.numberOfPlayersInOneGame - 1; i++) {
                    players.push({});
                }
                setOtherPlayers(players);
            });
    }

    function handleWinnerSelect(id) {
        setWinner({
            ...winner,
            id
        });
    }

    function handleWinnerScoreChange(score) {
        if (score) {
            score = Math.round(score);
        }
        setWinner({
            ...winner,
            score
        });
    }

    function handleOtherPlayersChange(index, id) {
        const players = [...otherPlayers];

        const player = {
            ...players[index],
            id
        };

        players[index] = player;

        setOtherPlayers(players);
    }

    function handleOtherPlayersScoreChange(index, score) {
        if (score) {
            score = Math.round(score);
        }

        const players = [...otherPlayers];

        const player = {
            ...players[index],
            score
        };

        players[index] = player;

        setOtherPlayers(players);
    }

    function handleTennisScoreChange(isWinner, scoreName, score) {
        const oldTennisScore = ((isWinner) ? winner.tennisScore : otherPlayers[0].tennisScore);

        const tennisScore = {
            ...oldTennisScore,
            [scoreName]: score
        }

        if (isWinner) {
            const player = {
                ...winner,
                tennisScore
            }
            setWinner(player);
        } else {
            const players = [...otherPlayers];

            const player = {
                ...players[0],
                tennisScore
            }

            players[0] = player;

            setOtherPlayers(players);
        }
    }

    function handleGameDurationChange(minutes) {
        setGameDuration(minutes);
    }

    function handleSubmit(e) {
        e.preventDefault();

        if (league.scoringType === "SIMPLE") {
            handleSimpleGame();
        } else if (league.scoringType === "TENNIS") {
            hanldeTennisGame();
        }
    }

    function handleSimpleGame() {
        if (winner && winner.id && (winner.score || winner.score == "0")) {
            let allSelected = true;

            otherPlayers.forEach(player => {
                if (!player.id || (!player.score && player.score != "0")) {
                    allSelected = false;
                }
            })


            if (allSelected) {
                const ids = otherPlayers.map(p => p.id);
                ids.push(winner.id);

                const hasDuplicates = ids.sort()
                    .some(function (item, i, items) {
                        return item === items[i + 1];
                    });

                if (!hasDuplicates) {
                    const data = {
                        winner: winner,
                        otherPlayers: otherPlayers
                    };

                    axios
                        .post(ServerParameters.API_URL + `/league/${params.leagueId}/add-game`, data)
                        .then((res) => {
                            navigate(`/leagues/${params.leagueId}/get`);
                            toast.success("Game added!");
                        });
                } else {
                    toast.error("Same player added twice!");
                }
            } else {
                toast.error("Not all information are filled!");
            }
        } else {
            toast.error("Not all information are filled!");
        }
    }

    function hanldeTennisGame() {
        const looser = otherPlayers[0];

        let valid = true;

        if (!winner.tennisScore) {
            valid = false;
        } else {
            if (!winner.id || !looser.id) {
                valid = false;
            }

            if ((winner.tennisScore.fifthSet || winner.tennisScore.fifthSetTieBreak) && !winner.tennisScore.fourthSet) {
                valid = false;
            }
            if ((winner.tennisScore.fourthSet || winner.tennisScore.fourthSetTieBreak) && !winner.tennisScore.thirdSet) {
                valid = false;
            }
            if ((winner.tennisScore.thirdSet || winner.tennisScore.thirdSetTieBreak) && !winner.tennisScore.secondSet) {
                valid = false;
            }
            if ((winner.tennisScore.secondSet || winner.tennisScore.secondSetTieBreak) && !winner.tennisScore.firstSet) {
                valid = false;
            }

            if (!winner.tennisScore.firstSet != !looser.tennisScore.firstSet) {
                valid = false;
            }
            if (!winner.tennisScore.secondSet != !looser.tennisScore.secondSet) {
                valid = false;
            }
            if (!winner.tennisScore.thirdSet != !looser.tennisScore.thirdSet) {
                valid = false;
            }
            if (!winner.tennisScore.fourthSet != !looser.tennisScore.fourthSet) {
                valid = false;
            }
            if (!winner.tennisScore.fifthSet != !looser.tennisScore.fifthSet) {
                valid = false;
            }
            if (!winner.tennisScore.firstSetTieBreak != !looser.tennisScore.firstSetTieBreak) {
                valid = false;
            }
            if (!winner.tennisScore.secondSetTieBreak != !looser.tennisScore.secondSetTieBreak) {
                valid = false;
            }
            if (!winner.tennisScore.thirdSetTieBreak != !looser.tennisScore.thirdSetTieBreak) {
                valid = false;
            }
            if (!winner.tennisScore.fourthSetTieBreak != !looser.tennisScore.fourthSetTieBreak) {
                valid = false;
            }
            if (!winner.tennisScore.fifthSetTieBreak != !looser.tennisScore.fifthSetTieBreak) {
                valid = false;
            }
        }

        if (valid) {
            const ids = otherPlayers.map(p => p.id);
            ids.push(winner.id);

            const hasDuplicates = ids.sort()
                .some(function (item, i, items) {
                    return item === items[i + 1];
                });

            if (!hasDuplicates) {
                const data = {
                    winner: winner,
                    otherPlayers: otherPlayers,
                    duration: gameDuration
                };

                axios
                    .post(ServerParameters.API_URL + `/league/${params.leagueId}/add-game`, data)
                    .then((res) => {
                        navigate(`/leagues/${params.leagueId}/get`);
                        toast.success("Game added!");
                    });
            } else {
                toast.error("Same player added twice!");
            }
        } else {
            toast.error("Not all information are filled or filled inncorect!");
        }
    }


    return <>
        <form className="pure-form" onSubmit={handleSubmit}>
            <>
                {league.scoringType === 'SIMPLE' &&
                    <fieldset>
                        <div className="pure-g margin-vertical-10">
                            <div className="pure-u-4-5">
                                <label className="gray-text">Winner</label>
                                <LeagueUserSearch
                                    className="margin-horizontal-5"
                                    leagueId={params.leagueId}
                                    onSelect={id => handleWinnerSelect(id)}
                                />
                            </div>
                            <div className="pure-u-1-5">
                                <label className="gray-text">Score</label>
                                <input
                                    className="pure-input-1 margin-horizontal-5"
                                    type="number"
                                    value={winner.score}
                                    onChange={e => handleWinnerScoreChange(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="gray-text">Other participants:</div>
                        {
                            otherPlayersIndexArray.map(index =>
                                <div key={index} className="pure-g margin-vertical-5">
                                    <div className="pure-u-4-5">
                                        <LeagueUserSearch
                                            leagueId={params.leagueId}
                                            onSelect={id => handleOtherPlayersChange(index, id)}
                                        />
                                    </div>
                                    <div className="pure-u-1-5">
                                        <input
                                            className="pure-input-1 margin-horizontal-5"
                                            type="number"
                                            value={otherPlayers[index].score}
                                            onChange={e => handleOtherPlayersScoreChange(index, e.target.value)}
                                        />
                                    </div>
                                </div>
                            )}
                        <div>
                            <input className="pure-button pure-button-primary" type="submit" value="Submit" />
                        </div>
                    </fieldset>
                }
            </>
            <>
                {league.scoringType === 'TENNIS' &&
                    <fieldset>
                        <div className="margin-vertical-10">
                            <label className="gray-text">Winner</label>
                            <LeagueUserSearch
                                className="margin-horizontal-5"
                                leagueId={params.leagueId}
                                onSelect={id => handleWinnerSelect(id)}
                            />
                        </div>
                        <div className="margin-vertical-10">
                            <label className="gray-text">Looser</label>
                            <LeagueUserSearch
                                className="margin-horizontal-5"
                                leagueId={params.leagueId}
                                onSelect={id => handleOtherPlayersChange(0, id)}
                            />
                        </div>
                        <table class="pure-table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>1-st set</th>
                                    <th>2-nd set</th>
                                    <th>3-rd set</th>
                                    <th>4-th set</th>
                                    <th>5-th set</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Winner tie-breaks</td>
                                    <td>
                                        <input
                                            size="1"
                                            className="tie-break-input"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            value={winner.tennisScore?.firstSetTieBreak}
                                            onChange={e => handleTennisScoreChange(true, "firstSetTieBreak", e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            size="1"
                                            className="tie-break-input"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            value={winner.tennisScore?.secondSetTieBreak}
                                            onChange={e => handleTennisScoreChange(true, "secondSetTieBreak", e.target.value)}

                                        />
                                    </td>
                                    <td>
                                        <input
                                            size="1"
                                            className="tie-break-input"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            value={winner.tennisScore?.thirdSetTieBreak}
                                            onChange={e => handleTennisScoreChange(true, "thirdSetTieBreak", e.target.value)}

                                        />
                                    </td>
                                    <td>
                                        <input
                                            size="1"
                                            className="tie-break-input"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            value={winner.tennisScore?.fourthSetTieBreak}
                                            onChange={e => handleTennisScoreChange(true, "fourthSetTieBreak", e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            size="1"
                                            className="tie-break-input"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            value={winner.tennisScore?.fifthSetTieBreak}
                                            onChange={e => handleTennisScoreChange(true, "fifthSetTieBreak", e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Winner sets</td>
                                    <td>
                                        <input
                                            size="5"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            value={winner.tennisScore?.firstSet}
                                            onChange={e => handleTennisScoreChange(true, "firstSet", e.target.value)}

                                        />
                                    </td>
                                    <td>
                                        <input
                                            size="5"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            value={winner.tennisScore?.secondSet}
                                            onChange={e => handleTennisScoreChange(true, "secondSet", e.target.value)}

                                        />
                                    </td>
                                    <td>
                                        <input
                                            size="5"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            value={winner.tennisScore?.thirdSet}
                                            onChange={e => handleTennisScoreChange(true, "thirdSet", e.target.value)}

                                        />
                                    </td>
                                    <td>
                                        <input
                                            size="5"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            value={winner.tennisScore?.fourthSet}
                                            onChange={e => handleTennisScoreChange(true, "fourthSet", e.target.value)}

                                        />
                                    </td>
                                    <td>
                                        <input
                                            size="5"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            value={winner.tennisScore?.fifthSet}
                                            onChange={e => handleTennisScoreChange(true, "fifthSet", e.target.value)}

                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Looser sets</td>
                                    <td>
                                        <input
                                            size="5"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            value={otherPlayers[0].tennisScore?.firstSet}
                                            onChange={e => handleTennisScoreChange(false, "firstSet", e.target.value)}

                                        />
                                    </td>
                                    <td>
                                        <input
                                            size="5"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            value={otherPlayers[0].tennisScore?.secondSet}
                                            onChange={e => handleTennisScoreChange(false, "secondSet", e.target.value)}

                                        />
                                    </td>
                                    <td>
                                        <input
                                            size="5"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            value={otherPlayers[0].tennisScore?.thirdSet}
                                            onChange={e => handleTennisScoreChange(false, "thirdSet", e.target.value)}

                                        />
                                    </td>
                                    <td>
                                        <input
                                            size="5"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            value={otherPlayers[0].tennisScore?.fourthSet}
                                            onChange={e => handleTennisScoreChange(false, "fourthSet", e.target.value)}

                                        />
                                    </td>
                                    <td>
                                        <input
                                            size="5"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            value={otherPlayers[0].tennisScore?.fifthSet}
                                            onChange={e => handleTennisScoreChange(false, "fifthSet", e.target.value)}

                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Looser tie-breaks</td>
                                    <td>
                                        <input
                                            size="1"
                                            className="tie-break-input"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            value={otherPlayers[0].tennisScore?.firstSetTieBreak}
                                            onChange={e => handleTennisScoreChange(false, "firstSetTieBreak", e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            size="1"
                                            className="tie-break-input"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            value={otherPlayers[0].tennisScore?.secondSetTieBreak}
                                            onChange={e => handleTennisScoreChange(false, "secondSetTieBreak", e.target.value)}

                                        />
                                    </td>
                                    <td>
                                        <input
                                            size="1"
                                            className="tie-break-input"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            value={otherPlayers[0].tennisScore?.thirdSetTieBreak}
                                            onChange={e => handleTennisScoreChange(false, "thirdSetTieBreak", e.target.value)}

                                        />
                                    </td>
                                    <td>
                                        <input
                                            size="1"
                                            className="tie-break-input"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            value={otherPlayers[0].tennisScore?.fourthSetTieBreak}
                                            onChange={e => handleTennisScoreChange(false, "fourthSetTieBreak", e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            size="1"
                                            className="tie-break-input"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            value={otherPlayers[0].tennisScore?.fifthSetTieBreak}
                                            onChange={e => handleTennisScoreChange(false, "fifthSetTieBreak", e.target.value)}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="pure-u-1-5">
                            <label className="gray-text">Game duration in minutes</label>
                            <input
                                className="pure-input-1 margin-horizontal-5"
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                value={gameDuration}
                                onChange={e => handleGameDurationChange(e.target.value)}
                            />
                        </div>
                        <div>
                            <input className="pure-button pure-button-primary" type="submit" value="Submit" />
                        </div>
                    </fieldset>
                }
            </>
        </form>

        <div><AddUser /></div>
    </>

}