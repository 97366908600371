import { useEffect, useState } from 'react';
import axios from 'axios'
import ServerParameters from "./ServerParameters";
import { useParams, useNavigate } from "react-router-dom"
import { toast } from 'react-toastify';

export default function AddUser({ callback }) {

    const [newUserEmail, setNewUserEmail] = useState("");
    const params = useParams();

    function handleNewUserEmailChange(e) {
        setNewUserEmail(e.target.value);
    }

    function addNewUser(e) {
        e.preventDefault();

        if (newUserEmail.length > 2) {
            const validEmail = String(newUserEmail)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );

            if (validEmail) {
                axios
                    .post(ServerParameters.API_URL + "/league/add-user-to-league", {
                        leagueId: params.leagueId,
                        email: newUserEmail
                    })
                    .then(response => {
                        setNewUserEmail("");
                        if (callback) {
                            callback();
                        }
                        toast.success("User added to league!");
                    });
            }
        }
    }

    return <>
        <div className="margin-10">
            <form className="pure-form">
                <div className="gray-text">Want to add a new user to league?</div>
                <button className="pure-button margin-right-5" onClick={(e) => addNewUser(e)}>Add user</button>
                <input
                    className="pure-input-1"
                    type="email"
                    value={newUserEmail}
                    placeholder="Enter user email.."
                    onChange={(e) => handleNewUserEmailChange(e)}
                />
            </form>
        </div>
    </>

}