import { useState } from 'react';
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

import ServerParameters from "./ServerParameters";


export default function RegistrationPage() {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();


    async function handleRegister(e) {
        e.preventDefault();

        if (validate(e)) {
            setUsername("");
            setPassword("");

            axios
                .post(ServerParameters.API_URL + "/user/register", { email: username, password: password })
                .then((res) => {
                    navigate(`/login`);
                    toast.success("User registered successfully! Please verify your email!");
                });
        }
    }


    function validate(e) {
        if (!username) {
            toast.error("Please enter your email!")
            return false;
        }
        if (!password) {
            toast.error("Please enter your password!");
            return false;
        }
        if (password.length < 4) {
            toast.error("Password minimum length are 4 symbols!");
            return false;
        }
        return true;
    }

    function handleUsernameChange(e) {
        setUsername(e.target.value);
    }

    function handlePasswordChange(e) {
        setPassword(e.target.value);
    }

    return (
        <>
            <form onSubmit={handleRegister} className="pure-form pure-form-stacked">
                <fieldset>
                    <div>
                        <input type="email" value={username} placeholder="Enter your email.." onChange={handleUsernameChange} />
                    </div>
                    <div>
                        <input type="password" value={password} placeholder="Enter your password.." onChange={handlePasswordChange} />
                    </div>
                    <div>
                        <input type="submit" value="Register" className="pure-button pure-button-primary" />
                    </div>
                </fieldset>
            </form>
        </>
    );


}