import { useEffect, useState } from 'react';
import axios from 'axios'
import ServerParameters from "./ServerParameters";
import { useParams, useNavigate } from "react-router-dom"
import AddUser from './AddUser';

export default function LeagueUsers() {

    const [users, setUsers] = useState([]);
    const params = useParams();

    useEffect(() => {
        loadUsers();
    }, []);

    function loadUsers() {
        axios
            .get(ServerParameters.API_URL + "/league/" + params.leagueId + "/users")
            .then(response => {
                setUsers(response.data);
            });
    }

    return <>
        <AddUser callback={loadUsers} />
        <table className="pure-table pure-table-horizontal margin-10">
            <tr>
                <th>Position</th>
                <th>User</th>
                <th>Games</th>
                <th>Wins</th>
                <th>Rating</th>
            </tr>
            {users.map(user =>
                <tr key={user.id}>
                    <td>{user.position}</td>
                    <td>{user.userEmail}</td>
                    <td>{user.totalGames}</td>
                    <td>{user.wins}</td>
                    <td>{user.rating}</td>
                </tr>
            )}
        </table>
    </>

}