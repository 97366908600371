import { useContext, useEffect } from 'react'
import axios from 'axios'
import UserContext from "./UserContex";
import ServerParameters from "./ServerParameters";
import { toast } from 'react-toastify';

const errorTranlations = {
    "UserAlreadyExistException": "User already exist!",
    "LeagueUserAlreadyExistException": "User already added to the league!",
    "DeleteNotEmptyLeagueException": "Can't delete league with games!",
    "NotOwnerTriesToDeleteLeagueException": "Only leagues creator can delete league!"
}

const WithAxios = ({ children }) => {
    const { user, setUser } = useContext(UserContext);

    useEffect(() => {
        axios.interceptors.response.use(response => response, async (error) => {
            const originalRequest = error.config;

            if (error.response.status === 400) {
                const errorText = errorTranlations[error.response.data.code];
                if (errorText) {
                    toast.error(errorText);
                } else {
                    toast.error("Bad input!")
                }
            } else if (error.response.status === 401
                && originalRequest.url != ServerParameters.API_URL + "/auth/refresh"
                && !originalRequest._retry) {
                originalRequest._retry = true;

                const refreshed = await axios
                    .post(ServerParameters.API_URL + "/auth/refresh")
                    .then((response) => true)
                    .catch((error) => {
                        axios.post(ServerParameters.API_URL + "/auth/logout");
                        return false;
                    });

                if (refreshed) {
                    return axios.request(originalRequest);
                } else {
                    setUser(null);
                }
            } else if (originalRequest.url != ServerParameters.API_URL + "/auth/refresh"  && !originalRequest._retry ) {
                toast.error("Unexpected error!");
            }
            return Promise.reject(error);
        })
    }, []);

    return children
}

export default WithAxios