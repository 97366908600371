import { useState } from 'react';
import axios from 'axios'
import ServerParameters from "./ServerParameters";
import { toast } from 'react-toastify';

export default function ForgotPasswordPage() {

    const [username, setUsername] = useState("");

    function handleUsernameChange(e) {
        setUsername(e.target.value);
    }

    async function handleForm(e) {
        e.preventDefault();

        if (validate()) {
            axios
                .post(ServerParameters.API_URL + "/auth/reset-password-request", {
                    email: username
                })
                .then(response => {
                    setUsername("");
                    toast.success("Instruction sent to your email!");
                });
        }
    }

    function validate(e) {
        if (!username) {
            toast.error("Please enter your email!")
            return false;
        }
        return true;
    }

    return <>
        <form className="pure-form pure-form-stacked" onSubmit={handleForm}>
            <fieldset>
                <div>
                    <input
                        type="email"
                        name="username"
                        value={username}
                        placeholder="Enter your email.."
                        onChange={(e) => handleUsernameChange(e)}
                    />
                </div>
                <input type="submit" value="Reset password" className="pure-button pure-button-primary" />
            </fieldset>
        </form>
    </>

}