import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState, useEffect, createContext } from 'react';
import { Navigate } from "react-router-dom";
import axios from "axios";

import RegistrationPage from "./RegistrationPage";
import LoginPage from "./LoginPage";
import Layout from "./Layout";
import HomePage from "./HomePage";
import UserContext from "./UserContex";
import ServerParameters from "./ServerParameters";
import WithAxios from "./WithAxios";
import LogoutPage from "./LogoutPage";
import LeaguesPage from "./LeaguesPage";
import LeaguePage from "./LeaguePage";
import CreateLeaguePage from "./CreateLeaguePage";
import PasswordResetPage from "./PasswordResetPage";
import VerifyEmailPage from "./VerifyEmailPage";
import AddGamePage from "./AddGamePage";
import { ToastContainer, toast } from 'react-toastify';
import ForgotPasswordPage from "./ForgotPasswordPage";

export default function Router() {

    const [user, setUser] = useState(null);

    useEffect(() => {
        axios
            .get(ServerParameters.API_URL + "/auth/account")
            .then((response) => {
                setUser(response.data);
            });
    }, []);

    return (
        <UserContext.Provider value={{ user: user, setUser: setUser }}>
            <ToastContainer />
            <WithAxios>
                <BrowserRouter>
                    <Routes>
                        {
                            user
                                ?
                                <Route path="/" element={<Layout />}>
                                    <Route path="leagues" element={<LeaguesPage />} />
                                    <Route path="leagues/create" element={<CreateLeaguePage />} />
                                    <Route path="leagues/:leagueId/get" element={<LeaguePage />} />
                                    <Route path="leagues/:leagueId/add-game" element={<AddGamePage />} />
                                    <Route path="logout" element={<LogoutPage />} />
                                    <Route path="*" element={<Navigate to="/leagues" replace />} />
                                </Route>
                                :
                                <Route path="/" element={<Layout />}>
                                    <Route path="registration" element={<RegistrationPage />} />
                                    <Route path="login" element={<LoginPage />} />
                                    <Route path="verify-email/:tokenId" element={<VerifyEmailPage />} />
                                    <Route path="forgot-password" element={<ForgotPasswordPage />} />
                                    <Route path="reset-password/:tokenId" element={<PasswordResetPage />} />
                                    <Route path="/" element={<Navigate to="/login" replace />} />
                                    <Route path="*" element={<Navigate to="/login" replace />} />
                                </Route>
                        }

                    </Routes>
                </BrowserRouter>
            </WithAxios>
        </UserContext.Provider>
    );

}