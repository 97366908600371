import { useEffect, useState } from 'react';
import axios from 'axios'
import ServerParameters from "./ServerParameters";
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";

export default function LeaguesPage() {

    const [leagues, setLeagues] = useState([]);
    const navigate = useNavigate();

    const addNewHandler = () => {
        navigate("/leagues/create");
    }

    useEffect(() => {
        axios
            .get(ServerParameters.API_URL + "/league/my-leagues")
            .then(response => {
                setLeagues(response.data);
            });
    }, []);

    return (
        <>
            <div>
                <h4>
                    You are in {leagues.length} leagues
                </h4>
            </div>
            <div className="center-text gray-text">
                <div>Want to join the other league?
                </div>
                <div> Ask league members for invitation or create your own league
                    <button onClick={addNewHandler} className="pure-button">+</button>
                </div>
            </div>
            <div>
                <ul>
                    {leagues.map(league =>
                        <ol key={league.id}>
                            <Link to={`/leagues/${league.leagueId}/get`} style={{ textDecoration: 'none' }}>
                                <div className="league-list-element">
                                    {league.leagueName}
                                    <span className="gray-text small-text"> (Players : {league.numberOfPlayersInOneGame})</span>
                                </div>
                            </Link>
                        </ol>
                    )}
                </ul>
            </div>
        </>
    );

}