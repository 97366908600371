import { useEffect } from 'react';

import AuthService from "./AuthService";

export default function LogoutPage() {
    const { logout } = AuthService();

    useEffect(() => {
        logout();
    }, []);

    // logout();

    return <></>;
}