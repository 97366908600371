import { useState } from 'react';
import AuthService from "./AuthService";
import UserContext from './UserContex';
import { useContext } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';


export default function LoginPage() {
    const { login } = AuthService();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();

    async function handleLogin(e) {
        e.preventDefault();

        if (validate()) {
            login(username, password);

            setUsername("");
            setPassword("");
        }
    }

    function validate(e) {
        if (!username) {
            toast.error("Please enter your email!")
            return false;
        }
        if (!password) {
            toast.error("Please enter your password!");
            return false;
        }
        return true;
    }

    function handleUsernameChange(e) {
        setUsername(e.target.value);
    }

    function handlePasswordChange(e) {
        setPassword(e.target.value);
    }

    const redirectToForgotPasswordPage = () => {
        navigate("/forgot-password");
    }

    return (
        <>
            <form className="pure-form pure-form-stacked" onSubmit={handleLogin}>
                <fieldset>
                    <div>
                        <input
                            type="email"
                            name="username"
                            value={username}
                            placeholder="Enter your email.."
                            onChange={(e) => handleUsernameChange(e)}
                        />
                    </div>
                    <div>
                        <input
                            type="password"
                            name="password"
                            placeholder="Enter your password.."
                            value={password}
                            onChange={(e) => handlePasswordChange(e)}
                        />
                    </div>
                    <div>
                        <a href="" onClick={redirectToForgotPasswordPage}>Forgot password?</a>
                    </div>
                    <input type="submit" value="Login" className="pure-button pure-button-primary" />
                </fieldset>
            </form>
        </>
    );
}