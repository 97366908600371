import { useEffect, useState } from 'react';
import axios from 'axios'
import ServerParameters from "./ServerParameters";
import { useParams, useNavigate } from "react-router-dom"

const convertMinsToHrsMins = (mins) => {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    h = h < 10 ? '0' + h : h; // (or alternatively) h = String(h).padStart(2, '0')
    m = m < 10 ? '0' + m : m; // (or alternatively) m = String(m).padStart(2, '0')
    return `${h}:${m}`;
}

export default function LeagueGames() {

    const [games, setGames] = useState([]);
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        loadGames();
    }, []);

    function loadGames() {
        axios
            .get(ServerParameters.API_URL + "/league/" + params.leagueId + "/games")
            .then(response => {
                setGames(response.data);
            });
    }

    function addGame() {
        navigate(`/leagues/${params.leagueId}/add-game`);
    }

    function deleteGame(gameId) {
        if (window.confirm('Are you sure you want to delete this game?')) {
            axios
                .delete(ServerParameters.API_URL + `/league/${params.leagueId}/games/${gameId}`)
                .then(response => {
                    loadGames();
                });
        } else {
            // Do nothing!
            console.log('Thing was not saved to the database.');
        }
    }

    return <>
        <div className="margin-vertical-10">
            <button className="pure-button" onClick={addGame}>+ Add game</button>
        </div>
        <ul>
            {games.map(game =>
                <ol key={game.id} className="game-entry container">
                    <div className="left-half">
                        <div>
                            <span className="game-winner">
                                {game.winner.email} {game.winner.score && <span className="game-score">{game.winner.score}</span>}
                            </span>
                            <span className="game-winner-rating-diff margin-horizontal-5">
                                {game.winner.rating}
                            </span>
                            <span className="game-winner-rating-diff">
                                (+{game.winner.ratingDiff})
                            </span>
                        </div>
                        <div>
                            {game.otherPlayers.map(player =>
                                <div>
                                    <span className="game-looser">
                                        {player.email} {player.score && <span className="game-score">{player.score}</span>}
                                    </span>
                                    <span className="game-winner-rating-diff margin-horizontal-5">
                                        {player.rating}
                                    </span>
                                    <span className="game-looser-rating-diff">
                                        ({player.ratingDiff})
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="right-half right-text">
                        <div>
                            <span className="small-text gray-text">
                                {new Date(game.createdAt * 1000).toLocaleString("sv-SE")}
                            </span>
                            <button className="pure-button delete-game-button" onClick={() => deleteGame(game.id)}>x</button>
                        </div>
                        <div className="small-text gray-text">
                            {game.duration && `duration: ${convertMinsToHrsMins(game.duration)}h`}
                        </div>
                    </div>
                    {game.winner.tennisScore &&
                        <table class="pure-table borderless-table">
                            <tbody>
                                <tr>
                                    <td>{game.winner.tennisScore.firstSet}<sup>{game.winner.tennisScore.firstSetTieBreak}</sup></td>
                                    <td>{game.winner.tennisScore.secondSet}<sup>{game.winner.tennisScore.secondSetTieBreak}</sup></td>
                                    <td>{game.winner.tennisScore.thirdSet}<sup>{game.winner.tennisScore.thirdSetTieBreak}</sup></td>
                                    <td>{game.winner.tennisScore.fourthSet}<sup>{game.winner.tennisScore.fourthSetTieBreak}</sup></td>
                                    <td>{game.winner.tennisScore.fifthSet}<sup>{game.winner.tennisScore.fifthSetTieBreak}</sup></td>
                                </tr>
                                <tr>
                                    <td>{game.otherPlayers[0].tennisScore.firstSet}<sup>{game.otherPlayers[0].tennisScore.firstSetTieBreak}</sup></td>
                                    <td>{game.otherPlayers[0].tennisScore.secondSet}<sup>{game.otherPlayers[0].tennisScore.secondSetTieBreak}</sup></td>
                                    <td>{game.otherPlayers[0].tennisScore.thirdSet}<sup>{game.otherPlayers[0].tennisScore.thirdSetTieBreak}</sup></td>
                                    <td>{game.otherPlayers[0].tennisScore.fourthSet}<sup>{game.otherPlayers[0].tennisScore.fourthSetTieBreak}</sup></td>
                                    <td>{game.otherPlayers[0].tennisScore.fifthSet}<sup>{game.otherPlayers[0].tennisScore.fifthSetTieBreak}</sup></td>
                                </tr>
                            </tbody>
                        </table>
                    }
                </ol>
            )}
        </ul>
    </>

}