import { useEffect, useState } from 'react';
import axios from 'axios'
import ServerParameters from "./ServerParameters";
import { useParams, useNavigate, Link } from "react-router-dom"
import { TabList, TabPanel, Tab, Tabs } from 'react-tabs';
import { toast } from 'react-toastify';
import LeagueUsers from './LeagueUsers';
import LeagueGames from './LeagueGames';

export default function LeaguePage() {

    const [league, setLeague] = useState({});
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        loadLeague();
    }, []);

    function loadLeague() {
        axios
            .get(ServerParameters.API_URL + "/league/" + params.leagueId)
            .then(response => {
                setLeague(response.data);
            });
    }

    function deleteLeague() {
        if (window.confirm('Are you sure you want to delete this league?')) {
            axios
                .delete(ServerParameters.API_URL + `/league/${params.leagueId}`)
                .then(response => {
                    navigate(`/leagues`);
                    toast.success("League deleted!");
                });
        } else {
            // Do nothing!
            console.log('Thing was not saved to the database.');
        }
    }

    return <>
        <h3>
            {league.name}
            <span className="gray-text small-text"> (Players : {league.numberOfPlayersInOneGame})</span>
            <button onClick={deleteLeague} className="pure-button gray-text small-text margin-horizontal-5">X</button>
        </h3>
        <div className="pure-menu pure-menu-horizontal">
            <Tabs className="pure-menu pure-menu-horizontal center-block">
                <TabList className="pure-menu-list">
                    <Tab className="pure-menu-item pure-menu-link finger-pointer">Users</Tab>
                    <Tab className="pure-menu-item pure-menu-link finger-pointer">Games</Tab>
                </TabList>
                <TabPanel>
                    <LeagueUsers />
                </TabPanel>
                <TabPanel>
                    <LeagueGames />
                </TabPanel>
            </Tabs>
        </div>
    </>

}