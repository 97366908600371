import axios from "axios";
import ServerParameters from "./ServerParameters";
import UserContext from './UserContex';
import { useContext } from "react";
import { ToastContainer, toast } from 'react-toastify';

const AuthService = () => {

    const { user, setUser } = useContext(UserContext);

    const login = (username, password) => {
        return axios
            .post(ServerParameters.API_URL + "/auth/login", {
                login: username,
                password: password
            })
            .then((response) => {
                if (response.status === 200) {
                    updateAccount();
                }
                if (response.status === 401) {
                    toast.error("Wrong credentials");
                }
            })
            .catch(error => {
                if (error.response.status === 401) {
                    toast.error("Wrong email or password!");
                }
            })
    }

    const updateAccount = () => {
        return axios
            .get(ServerParameters.API_URL + "/auth/account")
            .then((response) => {
                setUser(response.data);
            });
    }


    const logout = () => {
        return axios
            .post(ServerParameters.API_URL + "/auth/logout")
            .then((response) => {
                if (response.status === 200) {
                    setUser(null);
                }
            });
    }

    return { login, logout, updateAccount };
}


export default AuthService;