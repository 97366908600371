import { useState } from 'react';
import axios from 'axios'
import ServerParameters from "./ServerParameters";
import { toast } from 'react-toastify';
import { useParams, useNavigate } from "react-router-dom";

export default function PasswordResetPage() {

    const [newPassword, setNewPassword] = useState("");
    const params = useParams();
    const navigate = useNavigate();

    async function submit(e) {
        e.preventDefault();

        if (validate(e)) {
            setNewPassword("");

            axios
                .post(ServerParameters.API_URL + "/auth/reset-password", {
                    tokenId: params.tokenId,
                    newPassword: newPassword
                })
                .then(response => {
                    navigate(`/login`);
                    toast.success("Password changed!");
                });

        }
    }

    function validate(e) {
        if (!newPassword || newPassword.length < 4) {
            toast.error("Please enter new password!");
            return false;
        }
        if (newPassword.length < 4) {
            toast.error("Password minimum length are 4 symbols!");
            return false;   
        }
        return true;
    }

    function handlePasswordChange(e) {
        setNewPassword(e.target.value);
    }

    return <>
        <form onSubmit={submit} className="pure-form pure-form-stacked">
            <fieldset>
                <div>
                    <input type="password" value={newPassword} placeholder="Enter new password.." onChange={handlePasswordChange} />
                </div>
                <div>
                    <input type="submit" value="Submit" className="pure-button pure-button-primary" />
                </div>
            </fieldset>
        </form>
    </>

}