let url;

if (process.env.REACT_APP_ENV === 'LOCAL') {
	url = 'http://localhost:8070/dev-api';
} else if (process.env.REACT_APP_ENV === 'DEV') {
	url = 'https://dev.trackmyfights.com/dev-api';
} else if (process.env.REACT_APP_ENV === 'PROD') {
	url = 'https://trackmyfights.com/api';
} else {
	url = 'http://true-ip/api';
}

const ServerParameters = {
	API_URL: url
};

export default ServerParameters;