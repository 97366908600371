import { useEffect, useState } from 'react';
import axios from 'axios'
import ServerParameters from "./ServerParameters";
import { toast } from 'react-toastify';
import { useParams, useNavigate } from "react-router-dom";

export default function VerifyEmailPage() {

    const [verified, setVerified] = useState(false);
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        verify();
    }, []);

    function verify() {
        axios
            .post(ServerParameters.API_URL + "/auth/verify-email", {
                tokenId: params.tokenId
            })
            .then(response => {
                setVerified(true);
                toast.success("Email verified! Now you can login!");
            });
    }

    function login() {
        navigate(`/login`);
    }

    return <>
        {!verified &&
            <div>
                Email verification in progress..
            </div>
        }
        {verified &&
            <div>
                Email verified! Now you can <a href="" onClick={login()}>Login</a>.
            </div>
        }
    </>

}