import { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios'
import ServerParameters from "./ServerParameters";

export default function LeagueUserSearch({ leagueId, onSelect }) {
    const [user, setUser] = useState(null);
    const [users, setUsers] = useState([]);

    function loadUsers(text) {
        if (text && text.length > 0) {
            axios
                .get(ServerParameters.API_URL + "/league/" + leagueId + "/search-user", {
                    params: {
                        email: text
                    }
                })
                .then(response => {
                    const options = response.data.map(user => {
                        return {
                            value: user.id,
                            label: user.userEmail
                        }
                    });

                    setUsers(options);
                });
        }
    }

    function handleOnChange(value) {
        setUser(value);
        onSelect(value.value);
    }

    return <>
        <Select
            placeholder="Start typing.."
            value={user}
            onChange={value => handleOnChange(value)}
            options={users}
            onInputChange={text => loadUsers(text)}
        />
    </>
}  