import { useState } from 'react';
import Select from 'react-select'
import axios from 'axios'
import ServerParameters from "./ServerParameters";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function CreateLeaguePage() {

    const [leagueName, setLeagueName] = useState("");
    const [scoringType, setScoringType] = useState("SIMPLE");
    const [numberOfPlayersInOneGame, setNumberOfPlayersInOneGame] = useState();

    const navigate = useNavigate();

    const scoringTypeOptions = [
        { value: 'SIMPLE', label: 'Simple' },
        { value: 'TENNIS', label: 'Tennis' }
    ]

    async function handleCreate(e) {
        e.preventDefault();

        if (validate()) {
            axios
                .post(ServerParameters.API_URL + "/league/create", {
                    name: leagueName,
                    numberOfPlayersInOneGame: numberOfPlayersInOneGame,
                    scoringType: scoringType
                })
                .then(response => {
                    setLeagueName("");
                    setNumberOfPlayersInOneGame(null);
                    navigate("/leagues");
                    toast.success("New league created!");
                });
        }
    }

    function validate() {
        if (!leagueName) {
            toast.error("Please enter league name!")
            return false;
        }
        if (!numberOfPlayersInOneGame) {
            toast.error("Please enter number of players in one game!");
            return false;
        }
        return true;
    }

    function handleLeagueNameChange(e) {
        setLeagueName(e.target.value);
    }

    function handleNumberOfPlayersInOneGame(e) {
        if (scoringType === 'SIMPLE') {
            setNumberOfPlayersInOneGame(e.target.value);
        }
    }

    function handleScoringTypeChange(e) {
        setScoringType(e.value);
        if (e.value === 'TENNIS') {
            setNumberOfPlayersInOneGame(2);
        }
    }

    return <>
        <form className="pure-form pure-form-aligned" onSubmit={handleCreate}>
            <fieldset>
                <div className="pure-control-group">
                    <label for="aligned-name">League name</label>
                    <input
                        id="aligned-name"
                        type="text"
                        name="leagueName"
                        value={leagueName}
                        onChange={(e) => handleLeagueNameChange(e)}
                    />
                </div>
                <div className="pure-control-group">
                    <Select
                        id="aligned-name"
                        type="text"
                        name="scoringType"
                        defaultValue={scoringTypeOptions[0]}
                        options={scoringTypeOptions}
                        onChange={(e) => handleScoringTypeChange(e)}
                    />
                </div>
                <div className="pure-control-group">
                    <label for="aligned-number-of-players">Number of players in one game</label>
                    <input
                        id="aligned-number-of-players"
                        type="number"
                        min="2"
                        max="20"
                        step="1"
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        name="numberOfPlayersInOneGame"
                        value={numberOfPlayersInOneGame}
                        onChange={(e) => handleNumberOfPlayersInOneGame(e)}
                    />
                </div>
                <div>
                    <input className="pure-button pure-button-primary" type="submit" value="Create" />
                </div>
            </fieldset>
        </form>
    </>

}